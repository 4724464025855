/**
 * @module SalesFlow/controller
 */

import { Constants } from 'core/constants';

import {AnDeviceId, TariffGroupName, SubscriptionIdPerSalesChannel, BusinessTransactionContext} from 'core/ids';
import Subscription from 'model/type/subscription';
import AtomicDevice from 'model/type/atomic-device';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import ViewStopper from 'view/view/shared/stopper';
import Pricebox from 'view/element/shared/pricebox';
import {ViewEvolvedElementBntDeviceDetail} from 'view-evolved/element/bnt/view-evolved--element-bnt--device-detail';

import TechnicalDetails from 'view/element/bnt/technical-details';
import Alert from 'view/element/bnt/alert';
import Stopper from 'view/element/bnt/stopper';

import Injector from 'core/injector';
import ViewAlertButton from 'view/view/shared/alert-button';
import ViewAlertWarning from 'view/view/shared/alert-warning';
import ViewAccessoryOffer from 'view/view/shared/accessory-offer';
import HardwareOnlyOffer from 'view/view/shared/offer/hardwareonly-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';

import {ControllerEvolvedBaseClass} from 'controller-evolved/base-class/controller-evolved--base-class';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';
import BntDeviceFlowService from 'service/bnt/bnt-device-flow-service';

/**
 * I come from previous step:
 *      => Tariff is locked, device is locked
 */

export abstract class ControllerEvolvedBaseClassDeviceDetail extends ControllerEvolvedBaseClass {

    protected _incomingDeviceId: AnDeviceId;

    private _subscription: Subscription;
    protected _atomicDevice: AtomicDevice;

    protected _deviceOffer: DeviceOffer;
    protected _viewStopper: ViewStopper;

    protected _element: JQuery;
    private _hardwareOnlySelector: JQuery;

    private _pricebox: Pricebox;
    protected _deviceDetail: ViewEvolvedElementBntDeviceDetail;
    private _technicalDetails: TechnicalDetails;
    private _alert: Alert;
    protected _stopper: Stopper;
    protected _gigakombiDeviceDetailService: GigakombiDeviceDetailService;

    constructor (
        atomicDeviceId: AnDeviceId,
        focusSubscriptionIdArray: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(
            focusSubscriptionIdArray,
            reposSupervisor,
            injector
        );

        /**
         * No SalesChannel- (aka SubscriptionGroup-) in case of family&friends
         */
        if (Constants.SALESCHANNEL_FAMILY_FRIENDS ===  injector.getFlowState().getSalesChannel()) {
            $('body').append('<script type="text/javascript" src="/simplicity/assets/js/faf-check.js"></' + 'script>');
        }

        this._incomingDeviceId = atomicDeviceId;
        this._pricebox = new Pricebox(injector);
        this._deviceDetail = new ViewEvolvedElementBntDeviceDetail(injector);
        this._technicalDetails = new TechnicalDetails(injector);
        this._element = $('#nsf-device-detail');
        this._alert = new Alert(injector, this._element);
        this._hardwareOnlySelector = $('#nsf-hardware-only-toggle');
        this._gigakombiDeviceDetailService = new GigakombiDeviceDetailService(injector);

        // Todo: do we need the tariff group switcher here? We removed it for the Gigakombi story...
        // HBO: not needed anymore, I suppose
        // this._subscriptionGroupSwitcher = this.createTariffGroupSwitcher();

    }

    protected tracking (eventType?: string, overwriteRule?: boolean) {

        if (undefined === eventType) {
            eventType = 'pageviewTracking@onload';
        }

        this.getInjector().getEvent().trigger(eventType,
            {
                deviceOffer: this._deviceOffer,
                pageName: 'produktdetails',
                pageType: 'product detail'
            }
        );

    }

    public getElement (): JQuery {
        return this._element;
    }

    /**
     * Shows Message, that Device is not in stock
     */
    protected displayNoDataFoundError (): void {

        const btn: ViewAlertButton[] = [];

        btn.push(new ViewAlertButton('btn-alt', 'Vertragsverl&auml;ngerung', ''));
        btn.push(new ViewAlertButton('', 'Smartphones mit Vertrag', ''));

        this._alert.bind(new ViewAlertWarning('Das Smartphone haben wir leider gerade nicht. Das tut uns leid. Versuchen Sie es bitte in ein paar Tagen wieder.<br />Oder Sie sehen sich mal unsere anderen Smartphone-Angebote an.', '', btn));

        $('#nsf-stepper').hide();
        $('#nsf-pricebox').hide();
        $('#nsf-device-detail-subscriptions').hide();

    }

    private handleEventHardwareOnlySelected (avoidTracking: boolean) {

        this.getInjector().getFlowState().setHardwareOnly(true);
        this.getInjector().getFlowState().lockSubscription();

        /**
         * reset Red+ Data
         */
        this.getInjector().getFlowState().resetRedPlusFlowData();

        const selectedDeviceOffer = this.getDeviceOffer();

        $('#nsf-tariff-name').html('Smartphone ohne Tarif');
        BntDeviceFlowService.toggleHeadlineWithContract(this.getInjector().getFlow().getHardwareOnly());
        this._element.find('.insuranceBox').hide();

        this.getInjector().getOfferCollection().setActiveOffer(selectedDeviceOffer);

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: selectedDeviceOffer
        });

        if (true !== avoidTracking) {
            // We are on the device detail page, so the pageType is only product detail
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'handys',
                pageType: 'product detail',
                deviceOffer: selectedDeviceOffer
            });
        }

    }

    protected render (data?: any): void {
        /**
         * set HW only as selected
         */
        if (true === this.getInjector().getFlowState().getHardwareOnly()) {
            this._hardwareOnlySelector.find('.selectionHwOnly').addClass('selected');
        }

        this._subscriptionSelection._slider.update();
        this._subscriptionSelection._slider.updateHeight();
        this._subscriptionSelection._slider.scrollToFocus();
    }

    protected events (): void {

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            this._deviceOffer = this.getDeviceOffer();

            this._subscriptionSelection.update(this.getOffersMatchingToSlider());

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this._deviceOffer
            });
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'produktdetails',
                pageType: 'product detail',
                deviceOffer: this._deviceOffer
            });

        });

        this.getInjector().getEvent().listen('subscriptionId@changed', (eventObject: JQueryEventObject, data: any) => {

            const hardwareOnly = data.hardwareOnly;

            if (true === hardwareOnly) {
                this.getInjector().getFlowState().setSubscriptionId(0);
            } else {
                this.getInjector().getFlowState().setSubscriptionId(data.subscriptionId);
                this._element.find('.insuranceBox').show();
            }

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this.getDeviceOffer()
            });

            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'produktdetails',
                pageType: 'product detail',
                deviceOffer: this.getDeviceOffer()
            });
        });

        this.getInjector().getEvent().listen('hardwareonly@selected', (eventObject: JQueryEventObject, data: any) => {

            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventHardwareOnlySelected(avoidTracking);

        });

        this._deviceDetail.getElement().add(this._deviceDetail.getElement()).on('click', 'a[href*="#vfpre"]:not([href="#"])', (evt) => {

            const defaultSprungMarke = 'tab-highlights';

            const tHash = $(evt.currentTarget).data('hash');

            $('#tab-' + tHash).trigger('click');

            let hash = $(evt.currentTarget).attr('href').replace('vfpre', '');

            if ('#' === hash) {
                if (0 === tHash.length) {
                    hash += defaultSprungMarke;
                } else {
                    hash += 'tab-' + tHash;
                }
            }

            // @TODO BPe -> Script anpassen fuer Srollen in TAB innerhalb eines Overlays

            const target = $(hash);
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 800);

                return false;
            }

            return false;

        });

        this.getInjector().getEvent().listen('TariffGroup@finished', (eventObject: JQueryEventObject) => {

            if (true === this.getInjector().getFlow().getHardwareOnly()) {
                this._hardwareOnlySelector.find('.selectionHwOnly').addClass('selected');
            } else {
                $('#nsf-subscription-list-slide').find('[data-subscription-id="' + this.getInjector().getFlowState().getSubscriptionId() + '"]').addClass('selected');
            }

            this._deviceOffer = this.getDeviceOffer();

            BntDeviceFlowService.toggleHeadlineWithContract(this.getInjector().getFlow().getHardwareOnly());

            // switch promo bubbles
            if (true === this.getInjector().getFlow().getHardwareOnly()) {
                this._viewStopper = new ViewStopper(this._injector);
                this._element.find('.insuranceBox').hide();
            } else {
                this._viewStopper = new ViewStopper(this._injector, this._deviceOffer.subscription, this._deviceOffer);
                this._element.find('.insuranceBox').show();
            }

            this._stopper = new Stopper(this._injector);
            this._stopper.bind(this._viewStopper);

            /**
             * INCM-466: In case, a tariffGroupChange has taken place after a Red+ was selected
             * on X-Sell page, we have to remove the Red+ preselection.
             * The reason is, that the offerIds are stored in session Storage and the offerIds
             * differ from tariffGroup to tariffGroup
             * @private
             */
            this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.resetAllElements();
            this.getInjector().getFlowStateWithSalesChannel().redPlusBasic.resetAllElements();
            this.getInjector().getFlowStateWithSalesChannel().redPlusKids.resetAllElements();
            this.getInjector().getFlowStateWithSalesChannel().redPlusData.resetAllElements();

            this._injector.getEvent().trigger('subscriptionId@changed', {
                subscriptionId: this._injector.getFlow().getSubscriptionId()
            });
        });

    }

    protected getOffersMatchingToSlider (): DeviceOffer[] {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        const subscriptionIds = this._subscriptionSelection.getEvolvedSubscriptionIds(tariffGroup);

        const viewDeviceOffers: DeviceOffer[] = [];
        let viewDeviceOffer: DeviceOffer;

        for (const subscriptionId of subscriptionIds) {

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                this._atomicDevice.id,
                subscription.id,
                this._btx,
                salesChannel
            );

            if (undefined !== offer) {

                let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;
                if (Constants.BTX_GIGAKOMBI === this._btx) {
                    optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, subscription);
                }

                viewDeviceOffer = new DeviceOffer(this._atomicDevice, subscription, offer, [], optionalServiceIds);

                viewDeviceOffers.push(viewDeviceOffer);

            }

        }

        return viewDeviceOffers;
    }

    protected getDeviceOfferWithSubscription (): DeviceOffer {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        let defaultSubscriptionId = this._injector.getOptions().get('default_' + this.getInjector().getFlowState().getTariffGroup() + '_subscription_id');

        if (Constants.BTX_GIGAKOMBI === this._btx) {
            defaultSubscriptionId = this._injector.getOptions().get('default_gigakombi_' + this.getInjector().getFlowState().getTariffGroup() + '_subscription_id');
        }

        /**
         * valid subscription-id? otherwise default subscription-id
         */
        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
        if ('undefined' === typeof this._subscription) {
            this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(defaultSubscriptionId);
            this.getInjector().getFlowState().setSubscriptionId(defaultSubscriptionId, true);
        }

        /**
         * do offers exist for this subscription? otherwise default subscription-id
         */
        const simOnlyOffer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);
        if ('undefined' === typeof simOnlyOffer) {
            this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(defaultSubscriptionId);
            this.getInjector().getFlowState().setSubscriptionId(defaultSubscriptionId, true);
        }

        this._atomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
            atomicDeviceId,
            salesChannel,
            this._subscription
        );

        if (undefined === this._atomicDevice) {
            this.setDefaultSubscriptionIdOnUndefined();
        }

        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
            this._atomicDevice.id,
            this._subscription.id,
            this._btx,
            salesChannel
        );

        /**
         *  Did the user opts in a RedPlus Option
         */
        const offersRedPlus: any[] = [];
        const allRedPlusOptions = [
            ...this.getInjector().getFlowState().redPlusAllnet.elements,
            ...this.getInjector().getFlowState().redPlusData.elements,
            ...this.getInjector().getFlowState().redPlusKids.elements,
            ...this.getInjector().getFlowState().redPlusBasic.elements
        ];
        for (const offerId of allRedPlusOptions) {

            const redPlusOffer = this._generalSalesObjectInterface.getRedPlusOfferById(
                offerId,
                salesChannel);
            const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(redPlusOffer.subscriptionId);

            if ('HmK' === redPlusOffer.offerType) {
                let redPlusAtomicDevice: AtomicDevice;

                redPlusAtomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
                    redPlusOffer.deviceId,
                    salesChannel,
                    redPlusSubscription
                );

                offersRedPlus.push(new DeviceOffer(redPlusAtomicDevice, redPlusSubscription, redPlusOffer));
            } else {
                offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));
            }
        }

        /**
         * Selected accessories
         * TODO
         */

        const accessoryOffers: ViewAccessoryOffer[] = [];
        /*
        for (const accessoryId of this.getInjector().getFlowState().accessoryIds.elements) {
            const atomicAccessory = this._purchasableAccessoryRepo.getAtomicAccessory(accessoryId);
            const accessoryOffer = this._offerHardwareRepo.getAccessoryOffer(atomicAccessory);
            accessoryOffers.push(new ViewAccessoryOffer(accessoryOffer, atomicAccessory));
        }*/

        /**
         * Get selected optional services
         */

        let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;
        if (Constants.BTX_GIGAKOMBI === this._btx) {
            optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, this._subscription);
        }

        return new DeviceOffer(this._atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId), this._subscription, vluxOffer, offersRedPlus, optionalServiceIds, accessoryOffers);

    }

    protected getHardwareOnlyOffer (): DeviceOffer {

        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        this._subscription = undefined;

        this._atomicDevice = this.getGeneralDeviceRepo().getAtomicDeviceHardwareOnly(atomicDeviceId, this.getInjector().getFlowStateWithSalesChannel().getSalesChannel());
        if (undefined === this._atomicDevice && ('' === window.document.referrer || -1 !== window.document.referrer.indexOf(Constants.CUSTOMER_SALES_PAGE))) {
            /**
             * Changes [VGOF-5450] [INC-22805]
             * Add condition to check if document referrer is empty and no atomic device is set
             * to redirect the customer ` https://eweb8.vfd2-testnet.de/privat/mobilfunk.html`
             */
            window.location.href = Constants.CUSTOMER_SALES_MOBILE_PAGE;
        }

        /**
         * reset Red+ Data while hardware only could not have redplus
         */
        this.getInjector().getFlowState().resetRedPlusFlowData();

        const vluxOffer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId (
            this._atomicDevice.id,
            this.getInjector().getFlowStateWithSalesChannel().getSalesChannel()
        );

        return new HardwareOnlyOffer(this._atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId), vluxOffer);
    }

    protected getDeviceOffer (): DeviceOffer {

        if (false === this.getInjector().getFlowState().getHardwareOnly() && Constants.SUBSCRIPTION_GROUP_IN !== this.getInjector().getFlowState().getSubscriptionGroup()) {
            return this.getDeviceOfferWithSubscription();
        }
        else {
            return this.getHardwareOnlyOffer();
        }

    }

    /**
     * Incoming could be a DeviceId or an atomicDeviceId
     * This needs to be resolved as atmonicDeviceId, so if DeviceId get the first purchableAtomicDeviceFrom
     */
    private resolveIncomingDeviceId (): number {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        if ('atomicDeviceId' === this._incomingDeviceId.getType()) {
            return this._incomingDeviceId.get();
        }

        if ('deviceId' === this._incomingDeviceId.getType()) {

            const device = this.getGeneralDeviceRepo().getDevice(this._incomingDeviceId.get(), salesChannel);

            const atomicDevice = device.getAtomicDeviceByIndex(0);

            return atomicDevice.id;

        }

    }

    private addAklamioProperties () {
        const friendsTeaserButtonLink = $('#nsf-stopper-promo-modules').find('.not-open-overlay');

        // Just if there is a teaser found
        if (undefined !== friendsTeaserButtonLink.get(0)) {
            const dataImageUrl = this._deviceOffer.nfsLargeImages[0];
            const dataMedium = $('meta[name=id]').attr('content');
            const canonicalUrl = $('link[rel=canonical]').attr('href');
            const dataTargetUrl = canonicalUrl + '?b_id=1742&c_id=mgm_cic_12321:fq0_f_gen_sta_&j_id=MGMConPer12321F';
            const dataTitle = this._deviceOffer.atomicDevice.getDevice().name;

            // Just if the teaser has the aklamio class, this class is added by the editors
            if (friendsTeaserButtonLink.hasClass('aklamio-referral-widget')) {
                friendsTeaserButtonLink.attr({
                    'href': '#',
                    'data-uid': '6fd7f11750a6958402c3d8df89d7e281',
                    'data-campaign': 'vodafone_de',
                    'data-image-url': dataImageUrl,
                    'data-medium': dataMedium,
                    'data-source': 'PP',
                    'data-target-url': dataTargetUrl,
                    'data-title': dataTitle
                });
            }
        }
    }

    /**
     * @TODO Don't show tariffs not eligable
     */
    private setDefaultSubscriptionIdOnUndefined () {

        const defaultSubscriptionId = this._injector.getOptions().get('default_' + this.getInjector().getFlowState().getTariffGroup() + '_subscription_id');
        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(defaultSubscriptionId);

        this._atomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
            this.getInjector().getFlowState().getAtomicDeviceId(),
            this.getInjector().getFlowState().getSalesChannel(),
            this._subscription
        );
        this.getInjector().getFlowState().setSubscriptionId(defaultSubscriptionId);
    }

    protected fillOfferOfferCollection (): void {

        this.getInjector().getOfferCollection().setActiveOffer(
            this.getDeviceOffer()
        );

        this.getInjector().getOfferCollection().setSubscriptions(
            this.getOffersMatchingToSlider()
        );

        this.getInjector().getOfferCollection().log();

    }

    protected switchTeaser (tariffGroupName: string): void {
    }

    protected switchHeadlines (tariffGroupName: string): void {
    }

    public bind (): void {

        if (this._subscriptionGroupSwitcher) {
            this._subscriptionGroupSwitcher.bind();
        }
        const atomicDeviceId = this.resolveIncomingDeviceId();

        this.getInjector().getFlowState().setAtomicDeviceId(atomicDeviceId, true);

        // load
        this._deviceOffer = this.getDeviceOffer();

        this._stepper.bind();

        this._pricebox.bind(this._deviceOffer);

        if (undefined !== this._ctas) {
            this._ctas.bind(this._deviceOffer);
        }

        this._technicalDetails.setAttributeRepo(this.getReposSupervisor().getAttributeRepo());
        this._technicalDetails.bind(this._deviceOffer);

        this._deviceDetail.bind(this._deviceOffer);

        if (true === this.getInjector().getFlowState().getHardwareOnly()) {

            const headline = $('.text-only h1').parent().html();
            $('.text-only h1').parent().html(headline.replace('mit', 'ohne'));

            $('.insuranceBox').hide();

            /** re-init Stopper after displaying detail data */
            this._viewStopper = new ViewStopper(this._injector);

        } else {
            this._viewStopper = new ViewStopper(this._injector, this._deviceOffer.subscription, this._deviceOffer);
        }
        this._stopper = new Stopper(this._injector);
        this._stopper.bind(this._viewStopper);

        this.events();

        const url = this._deviceOffer.atomicDevice.getDevice().getDetailLink(this.getInjector().getOptions().get('device_detail_prefix'));
        this.getInjector().getEvent().trigger('stepper@device_detail_url', url);

        /**
         * prepare tariffs and offeres for tariff slider
         */
        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        if (undefined !== subscriptionId) {
            this._subscriptionSelection.setActiveSubscriptionId(
                subscriptionId
            );
        }

        this._subscriptionSelection.bind(this.getOffersMatchingToSlider());

        this.render();

        // That should be part of validating
        const OK: boolean = true;
        if (!OK) {

            this.displayNoDataFoundError();

        }

        this.getInjector().getLoadingIndicator().hide();

        /**
         * Add aklamio overlay properties for bnt and gigakombi flows. Script initializing the aklamio overlays is handled through the CMS.
         */
        this.addAklamioProperties();

        this.tracking('pageviewTracking@onload');

        // check, if tradeIn is selected and if so preselect checkbox
        if (this.hasTradeInSelected() && this._deviceOffer.hasTradeIn) {
            $('.tradeinBox > .checkBox').addClass('selected');
        } else {
            this.getInjector().getFlow().optionalServiceIds.removeElement(Constants.TradeInDiscount_Id);
        }

    }

}
